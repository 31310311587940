import * as React from "react"
import { UploadedFileT } from "types/Common"
import { getImageFile, getImageBreakpoint } from "../../utilities/Image"
import classNames from "classnames"

type Props = {
  image: UploadedFileT
  className?: string
  formats: Array<string>
  width: number
  height: number
  fallbackFormat: string
  title?: string
  rounded?: boolean
  lazyLoading?: boolean
}

const ResponsiveImage: React.ComponentType<Props> = ({
  image,
  className,
  formats,
  width,
  height,
  fallbackFormat,
  title,
  rounded,
  lazyLoading = true,
}) => {
  if (!image) {
    return null
  }

  return (
    <picture className={className}>
      {formats.map((format, index) => {
        return (
          <source
            key={index}
            media={getImageBreakpoint(format, width)}
            srcSet={getImageFile(image.id, format + "_webp", image.formats)}
            type="image/webp"
          />
        )
      })}

      {formats.map((format, index) => {
        return (
          <source
            key={index}
            media={getImageBreakpoint(format, width)}
            srcSet={getImageFile(image.id, format + "_jpeg", image.formats)}
            type="image/jpeg"
          />
        )
      })}

      <img
        width={width}
        height={height}
        loading={lazyLoading ? "lazy" : "eager"}
        className={classNames({ "rounded-full": rounded })}
        src={getImageFile(image.id, fallbackFormat, image.formats)}
        alt={title ?? image.name}
      />
    </picture>
  )
}

export default ResponsiveImage
