import React, { useEffect, useMemo, useState } from "react"
import { BlogCategoriesT, BlogsT, ConfigurationT } from "types/Common"
import { useLocation } from "@gatsbyjs/reach-router"

import Container from "../../atoms/Container"
import BlogListFilter from "./BlogListFilter"
import BlogListItem from "./BlogListItem"
import FollowUs from "../BlockFollowUs"
import { getConfiguration } from "utilities/configuration"

type PropsT = {
  blogs: BlogsT
  blogCategories: BlogCategoriesT
  configurations: ConfigurationT[]
}

const BlogList: React.ComponentType<PropsT> = ({
  blogs,
  blogCategories,
  configurations,
}) => {
  const params = useLocation()
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setSelectedCategoryId(params.get("category"))
  }, [params.search])

  const handleSelectCategory = value => {
    setSelectedCategoryId(value)
  }

  const filteredBlogs = useMemo(
    () =>
      blogs.filter(article => {
        return !selectedCategoryId
          ? true
          : selectedCategoryId == article.category.id
      }),
    [selectedCategoryId]
  )

  const facebookLink = getConfiguration(configurations, "SOCIAL_FACEBOOK_LINK")
  const linkedInLink = getConfiguration(configurations, "SOCIAL_LINKEDIN_LINK")
  const instagramLink = getConfiguration(configurations, "SOCIAL_INSTAGRAM_LINK")
  const twitterLink = getConfiguration(configurations, "SOCIAL_TWITTER-INVERT_LINK")

  return (
    <Container size="md">
      <BlogListFilter
        blogCategories={blogCategories}
        selectedCategory={selectedCategoryId}
        onSelectCategory={handleSelectCategory}
      />
      {filteredBlogs.map(article => (
        <BlogListItem key={article.id} article={article} />
      ))}
      <FollowUs facebookLink={facebookLink} linkedInLink={linkedInLink} instagramLink={instagramLink} twitterLink={twitterLink} />
    </Container>
  )
}

export default BlogList
