import React from "react"
import { BlogT, UploadedFileT } from "types/Common"
import Banner from "./Banner"
import BlockTitle from "../atoms/BlockTitle"
import styled from "styled-components"
import BlogItemInfo from "../organisms/BlogList/BlogItemInfo"
import { mediaQueries } from "styles/variables"
import { useTranslation } from "react-i18next"

type PropsT = {
  article: BlogT
  isButtonInVisible?: boolean
}

const Title = styled(BlockTitle)`
  max-width: 12.8em;
  margin-bottom: 0.88em;

  ${mediaQueries.smMin} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const BlogBanner = ({ article, isButtonInVisible }: PropsT) => {
  const { t, i18n } = useTranslation()
  const readArticle = t("readArticle")
  const path =
    i18n.language === "en"
      ? `/en/blog/${article.slug}`
      : `/blog/${article.slug}`

  const imageOriginal = article.coverImage
  const imageTablet = article.coverImageTablet
  const imageMobile = article.coverImageMobile

  return (
    <>
      <Banner
        background={imageOriginal}
        backgroundTablet={imageTablet}
        backgroundMobile={imageMobile}
        link={{ path: path, title: readArticle }}
        title={{
          title: article.title,
          type: "h1",
          tag: "h2",
        }}
        wideText={true}
        isButtonInVisible={isButtonInVisible}
      >
        <BlogItemInfo
          created={article.created_at}
          author={article.author}
          category={article.category}
          publishedAt={article.blogPublishedAt}
          text={article.text}
          perex={article.perex}
          isBanner
          iconColor={"text-white"}
        />
      </Banner>
    </>
  )
}

export default BlogBanner
