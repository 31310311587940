import * as React from "react"
import { InputFieldT } from "types/Common"
import styled from "styled-components"
import { colors } from "styles/variables"
import classNames from "classnames"

type Props = {
  options: { label: string; value: string | number | null }[]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  className?: string
  bordered?: boolean
}

const StyledSelectInputWrapper = styled.div`
  &::before {
    font-size: 2rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: ${colors.primary};
    pointer-events: none;
  }
`

const SelectInput2: React.ComponentType<Props> = ({
  options,
  onChange,
  className,
  bordered,
}) => {
  const selectClassName = classNames(
    "appearance-none p-3 w-full bg-white border-b-2 border-quantcomBlue font-bold outline-none text-quantcomDarkBlue placeholder-gray-900 focus:outline-none focus:shadow-outline",
    { "border-2": bordered }
  )

  return (
    <StyledSelectInputWrapper
      className={classNames("w-full relative icon-dart-bottom", className)}
      $bordered={bordered}
    >
      <select onChange={onChange} className={selectClassName}>
        {options.map(option => {
          return (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          )
        })}
      </select>
    </StyledSelectInputWrapper>
  )
}

export default SelectInput2
