import * as React from "react"
import SEO from "components/seo"
import Blog from "../components/organisms/BlogList/BlogList"
import { ClientsT, PageContextT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import BlogBanner from "../components/molecules/BlogBanner"
import { useTranslation } from "react-i18next"

type Props = {
  pageContext: PageContextT
  clients: ClientsT
}

const IndexPage: React.ComponentType<Props> = (props: Props) => {
  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      blogs,
      blogCategories,
      configurations,
      mobileHeaderLinks,
    },
  } = props

  const activePage = otherHeaderLinks.find(link => link.page.id === page.id)
  const { i18n } = useTranslation()

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      activePage={activePage}
      breadCrumbs={[]}
      localizedPage={{
        id: 1,
        title: "Blog",
        url: i18n.language === "en" ? "/blog" : "/en/blog",
        isHomepage: false,
        order: 1,
        page: null,
        section: null,
        parent: null,
      }}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
      />
      <BlogBanner article={blogs[0]} />
      <Blog
        blogs={blogs}
        blogCategories={blogCategories}
        configurations={configurations}
      />
    </Layout>
  )
}

export default IndexPage
