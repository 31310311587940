import React from "react"
import { BlogT } from "types/Common"
import ResponsiveImage from "../../atoms/ResponsiveImage"
import styled from "styled-components"
import Box from "../../molecules/Box"
import { mediaQueries } from "styles/variables"
import BlogItemInfo from "./BlogItemInfo"
import { useTranslation } from "react-i18next"

type PropsT = {
  article: BlogT
}

const StyledBox = styled(Box)`
  hr {
    transition: border 0.1s ease;
  }

  &:hover {
    hr {
      ${mediaQueries.xlMin} {
        border-color: white;
        transition-duration: 0.4s;
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 350px;
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  &::after {
    content: "";
    padding-top: 38.29%;
    display: block;
    ${mediaQueries.lgMin} {
      padding-top: 72.145%;
    }
    ${mediaQueries.lgMax} {
      height: 259px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

const Perex = styled.p`
  margin: 1.85rem 0 2.1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(2px);
  ${mediaQueries.lgMin} {
    font-size: 1.4rem;
    min-height: 2.92em;
    padding-right: 2.5rem;
  }
`

const BlogListItem: React.FunctionComponent<PropsT> = ({ article }) => {
  const {
    author,
    category,
    created_at,
    slug,
    perex,
    text,
    title,
    image,
    blogPublishedAt,
  } = article

  const { i18n } = useTranslation()

  return (
    <StyledBox
      link={i18n.language === "en" ? `/en/blog/${slug}` : `/blog/${slug}`}
      className="my-11"
      fitting
    >
      <div className="lg:flex">
        <ImageWrapper>
          <ResponsiveImage
            image={article.image}
            formats={["original"]}
            fallbackFormat={"original_jpeg"}
            width={350}
            height={259}
          />
        </ImageWrapper>
        <div className="md:pr-6 lg:pl-16 w-full relative">
          <h2 className="mt-8 mb-5 text-2xl sm:text-4xl font-bold text-quantcomBlue">
            {title}
          </h2>
          <BlogItemInfo
            author={author}
            category={category}
            created={created_at}
            publishedAt={blogPublishedAt}
            text={text}
            perex={perex}
            noCategoryLink
          />
          <Perex>{perex}</Perex>
          <hr className="border-gray-300 xl:absolute xl:bottom-0 xl:right-6 xl:left-16" />
        </div>
      </div>
    </StyledBox>
  )
}

export default BlogListItem
