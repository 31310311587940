import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"

type PropsT = {
  active: boolean
  category?: number
}

const BlogFilterButton: React.FunctionComponent<PropsT> = ({
  children,
  active,
  category,
}) => {
  return (
    <Link
      to={category ? `?category=${category}` : "?"}
      className={classNames(
        "my-1 mr-1 m-l px-12 py-4 uppercase font-bold hover:text-white hover:bg-quantcomBlue",
        {
          "text-quantcomDarkBlue": !active,
          "bg-quantcomBlue": active,
          "text-white": active,
        }
      )}
    >
      {children}
    </Link>
  )
}

export default BlogFilterButton
