import React from "react"
import { BlogCategoriesT } from "types/Common"
import BlogFilterButton from "./components/BlogFilterButton"
import SelectInput2 from "../../atoms/SelectInput2"
import { navigate } from "@reach/router"
import { useTranslation } from "react-i18next"

type PropsT = {
  blogCategories: BlogCategoriesT
  selectedCategory: number | null
  onSelectCategory: (a: number | null) => void
}

const BlogListFilter: React.FunctionComponent<PropsT> = ({
  blogCategories,
  selectedCategory,
}) => {
  const { t } = useTranslation()
  const options = [
    { label: t("filterAll"), value: 0 },
    ...blogCategories.map(category => ({
      label: category.name,
      value: category.id,
    })),
  ]

  const handleChange = event => {
    navigate(
      event.currentTarget.value === "0"
        ? "?"
        : `?category=${event.currentTarget.value}`
    )
  }

  return (
    <div className="my-20 text-center">
      <SelectInput2
        options={options}
        onChange={handleChange}
        bordered
        className="sm:hidden"
      />

      <div className="border-2 border-quantcomBlue pl-1 hidden sm:inline-flex">
        <BlogFilterButton active={selectedCategory === null}>
          {t("filterAll")}
        </BlogFilterButton>
        {blogCategories.map(category => (
          <BlogFilterButton
            key={category.id}
            category={category.id}
            active={selectedCategory == category.id}
          >
            {category.name}
          </BlogFilterButton>
        ))}
      </div>
    </div>
  )
}

export default BlogListFilter
